<template>
	<div id="index">

		<headers :activeItem="0" />

		<div class="content vertical_center">

			<div class="video" v-if="isMobile !== null">

				<video
					src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/abd3c704dfd83dc45c230eb74c55505f.mp4"
					preload="metadata"
					poster="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/homeVideoImg.png"
					webkit-playsinline="true" playsinline="true" x5-video-player-type="h5"
					x5-video-player-fullscreen="true" class="video-box" id="video">
					您的浏览器不支持 video 标签
				</video>
				<div class="iphone-case">
					<div class="icons" id="playIcons" @click="plays()">
						<div class="play-icons"></div>
					</div>
				</div>
			</div>

			<div class="video" v-else>
				<video muted="" loop="" autoplay webkit-playsinline='true' playsinline='true' class="video-box"
					src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/abd3c704dfd83dc45c230eb74c55505f.mp4" preload="auto"></video>
				<div class="iphone-case"> </div>
			</div>
			<div class="right">
				<div class="logo">
					<img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/logo.png"
						class="img_100" alt="">
				</div>
				<div class="right">
					<div class="desc">
						全品类交易平台<br />上门回收秒变现
					</div>
					<div class="download">
						<a href="#" id="xcx">
							<i class="icon-xcx"></i>
							小程序
						</a>
						<a href="#" id="gzh">
							<i class="icon-gzh"></i>
							公众号
						</a>
					</div>
				</div>
			</div>
		</div>

		<footers />
	</div>
</template>

<script>
	import headers from "./components/headers.vue";
	import footers from "./components/footers.vue";
	export default {
		name: "index",
		components: {
			headers,
			footers
		},
		data() {
			return {
				isMobile: null
			}
		},
		created() {
			this.isMobile = this._isMobile()
			console.log(this.isMobile)
		},
		methods: {

			//判断手机端还是PC端
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},

			plays() {
				var video = document.getElementById("video");
				video.play();

				document.getElementById("playIcons").style.display = "none";

			}
		
		}
	}
</script>

<style scoped>
	#index {
		width: 100%;
		height: 100vh;
		background: rgba(31, 77, 13, 1);
	}

	.content {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.video {
		width: 10rem;
		height: 21.5rem;
		margin-right: 3rem;
		position: relative;
		padding-top: .5REM;
		text-align: center;
	}

	.iphone-case {
		position: absolute;
		background-image: url('https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/tel.png');
		background-repeat: no-repeat;
		width: 10.5rem;
		height: 22.5rem;
		background-size: 100% 100%;
		left: 50%;
		transform: translateX(-50%);
		top: -.3rem;
	}

	.video .video-box {
		/* width: 100%;
		height: 100%; */
		width: 10.5rem;
		height: 20.75rem;
		/* padding: .25rem; */
		/* border-radius: 1.75rem; */
	}

	.icons {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0);

	}

	.play-icons {
		width: 2.5rem;
		height: 2.5rem;
		background: rgba(0, 0, 0, .3) url('../assets/play.png') no-repeat;
		background-size: 100% 100%;
		background-position: center;
		border-radius: 100%;
	}

	.right .logo {
		width: 8rem;
		height: 8rem;
	}

	.right .right {}

	.right .desc {
		color: rgba(255, 255, 255, 1);
		font-weight: bold;
		font-size: 1.8rem;
		line-height: 1.4;
	}

	.download {
		margin-top: 2rem;
	}

	.download a {
		display: inline-block;
		line-height: 2rem;
		text-align: center;
		width: 8rem;
		height: 2rem;
		border-radius: 1rem;
		font-size: 0.75rem;
		color: rgba(51, 51, 51, 1);
		background: rgba(255, 255, 255, 1);
		margin-right: 1rem;
		position: relative;
		transition: none;
	}

	.icon-xcx {
		display: inline-block;
		background: url('../assets/xiaochengxu.png') no-repeat;
		background-size: 100% 100%;
		width: 1.25rem;
		height: 1.25rem;
		margin-left: 0.5rem;
		vertical-align: middle;
	}

	.icon-gzh {
		display: inline-block;
		background: url('../assets/gzh.png') no-repeat;
		background-size: 100% 100%;
		width: 1.25rem;
		height: 1.25rem;
		margin-left: 0.5rem;
		vertical-align: middle;
	}

	.download a:after {
		content: "";
		position: absolute;
		top: 2rem;
		left: 0;
		width: 100%;
		height: 6.7rem;
		border-radius: 0.6rem 0.6rem 0.6rem 0.6rem;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		opacity: 0;
	}

	.download a:before {
		content: "";
		position: absolute;
		top: 1.75rem;
		left: 0;
		width: 100%;
		height: 7.1rem;
		border-radius: 0 0 1rem 1rem;
		background-color: #fff;
		opacity: 0;
	}

	#xcx:after {
		background-image: url("https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/xcx-.jpg");
	}

	#gzh:after {
		background-image: url("https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/gzh.jpg");
	}

	.download a:hover {
		border-radius: 1rem 1rem 0 0;
	}

	.download a:hover:before,
	.download a:hover::after {
		top: 2rem;
		opacity: 1;
	}

	.cash {
		position: fixed;
		top: 0;
		width: 100%;
	}
</style>
